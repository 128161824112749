import React from "react"

const Home = () => (
  <div
    style={{
      padding: "5rem",
    }}
  >
    <h1>This site is under construction!</h1>
  </div>
)

export default Home
